<template>
	<div class="container">
		<div class="is-flex is-align-items-center is-justify-content-center mb-3">
			<figure class="image is-128x128">
				<img src="@/assets/logo.svg">
			</figure>
		</div>
		<div class="columns is-centered">
			<div class="column is-two-fifths">
				<div class="card">
					<header class="card-header">
						<p class="card-header-title">
							<span class="icon"><i class="mdi mdi-lock default"></i></span>
							<span>{{ $t('Recover password') }}</span>
						</p>
					</header>
					<div class="card-content">
						<form @submit.prevent="recoverPassword">
							<b-field :label="$t('E-mail')" :message="errors.email" :type="{ 'is-danger': errors.email }" label-position="on-border">
								<b-input v-model="email" type="email"></b-input>
							</b-field>
							<div class="field">
								<button :class="{ 'is-loading': isSubmitting }" class="button is-link" type="submit"> {{ $t('Recover password') }}</button>
							</div>
							<hr>
							<div class="has-text-centered is-size-7">
								<router-link to="/sign-in">{{ $t('Sign in') }}</router-link> &middot;
								<router-link to="/sign-up">{{ $t('Sign up') }}</router-link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "RecoverPassword",
	mounted() {
		document.title = this.$t('Recover password') + ' - GIS ANCPI'
		this.$scrollToTop()
	},
	watch: {
		'$i18n.locale'() {
			document.title = this.$t('Recover password') + ' - GIS ANCPI'
		}
	},
	data() {
		return {
			errors: {},
			email: '',
			isSubmitting: false
		}
	},
	methods: {
		isValid() {
			this.errors = {}
			if (!this.email) {
				this.errors.email = this.$t('This field is mandatory!')
			}
			return !Object.keys(this.errors).length
		},
		recoverPassword() {
			if (!this.isValid()) {
				this.$buefy.toast.open({
					duration: 3000,
					message: this.$t('Check the errors in page!'),
					type: 'is-danger',
					position: 'is-bottom'
				})
				return false
			}
			this.isSubmitting = true
			this.$store.dispatch('recoverPassword', {
				email: this.email
			}).then(response => {
				this.isSubmitting = false
				this.$buefy.toast.open({
					duration: 3000,
					message: this.$t(response.data.message),
					type: 'is-success',
					position: 'is-bottom'
				})
				this.$router.push('/')
			}).catch(error => {
				this.isSubmitting = false
				this.$buefy.toast.open({
					duration: 3000,
					message: this.$t(error.response ? error.response.data.message : error),
					type: 'is-danger',
					position: 'is-bottom'
				})
			})
		}
	}
}
</script>

<style scoped>

</style>
